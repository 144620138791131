import React from "react";
import "./DynamicSearchTable.css";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { yellowGold } from "../../Assets/Theme/ThemeColors";

const DynamicSearchTable = ({ data, isLoaded }) => {
  return (
    <div className="dt-tableContainer">
      {isLoaded ? (<LinearProgress color="inherit" />) : ( [] )}
      <table className="dt-table">
        <thead>
          <tr>
            {data?.columns?.map((col) => (
              <th key={col.field}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.rows.length > 0 ? (
            data?.rows?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {data?.columns?.map((col) => (
                  <td key={col.field}>
                    {row[col.field] != null && row[col.field] !== ""
                      ? row[col.field]
                      : "-"}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>No Records Found</tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicSearchTable;
