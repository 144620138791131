import React, { useEffect, useState } from "react";
import "./Pagination.css";

const Pagination = ({ pageData, setPageData, isLoaded }) => {
  const [buttons, setbuttons] = useState([]);
  useEffect(() => {
    setPageData({
      ...pageData,
      buttonStart:
        pageData?.totalPages < pageData?.buttonsCount
          ? 1
          : pageData.buttonStart,
      buttonEnd:
        pageData?.totalPages < pageData?.buttonsCount
          ? pageData?.totalPages
          : pageData.buttonStart + pageData.buttonsCount,
    });
  }, []);
  useEffect(() => {
    console.log("Pagination compnent", pageData);
    let tempBtns = [];
    let totalBtns =
      pageData.totalPages < pageData.buttonsCount
        ? pageData.totalPages
        : pageData.buttonEnd;
    for (let i = pageData.buttonStart; i <= totalBtns; i++) {
      tempBtns.push(
        <button
          key={i}
          disabled={pageData.page === i}
          onClick={() => {
            setPageData({ ...pageData, page: i });
          }}
        >
          {i}
        </button>
      );
    }
    setbuttons(tempBtns);
  }, [pageData]);

  const NextPage = () => {
    if (pageData.page != pageData.totalPages) {
      let lastBtn = pageData.buttonEnd;
      let fstBtn = pageData.buttonStart;

      if (pageData.buttonEnd < pageData.totalPages) {
        lastBtn = pageData.buttonEnd + 1;
        fstBtn = pageData.buttonStart + 1;
      } else {
        lastBtn = pageData.buttonEnd;
        fstBtn = pageData.buttonStart;
      }

      // let newPage = forward < pageData.totalPages ? forward : pageData.page;
      let nextNewPage = pageData.page;
      if (pageData.page < pageData.totalPages) {
        nextNewPage = pageData.page + 1;
      }
      setPageData({
        ...pageData,
        page: Number(nextNewPage),
        buttonEnd: Number(lastBtn),
        buttonStart: Number(fstBtn),
      });
    }
  };

  const PreviousPage = () => {
    let lastBtn = pageData.buttonEnd;
    let fstBtn = pageData.buttonStart;

    if (pageData.buttonStart > 1) {
      lastBtn = pageData.buttonEnd - 1;
      fstBtn = pageData.buttonStart - 1;
    } else {
      lastBtn = pageData.buttonEnd;
      fstBtn = pageData.buttonStart;
    }

    let previous = pageData.page - 1;
    let newPage = previous >= 1 ? previous : pageData.page;
    setPageData({
      ...pageData,
      page: Number(newPage),
      buttonEnd: Number(lastBtn),
      buttonStart: Number(fstBtn),
    });
  };

  return (
    <div className="pagination">
      <p>
        Showing{" "}
        {pageData?.page > 1
          ? (pageData?.page - 1) * pageData.limit
          : pageData.page}{" "}
        to{" "}
        {pageData?.page * pageData.limit > pageData?.totalRecords
          ? pageData?.totalRecords
          : pageData?.page * pageData.limit}{" "}
        of {pageData?.totalRecords} entries
      </p>

      {/* <>Pagination {pageData?.page}</> */}
      {pageData?.totalPages > 0 ? (
        <>
          <p>
            <button onClick={PreviousPage}>Previous</button>
            {pageData?.buttonStart > 1 &&
            pageData.totalPages > pageData.buttonsCount ? (
              <>
                <button
                  onClick={() => {
                    setPageData({
                      ...pageData,
                      page: 1,
                      buttonEnd:
                        pageData?.totalPages < pageData.buttonsCount
                          ? pageData?.totalPages
                          : pageData.buttonsCount,
                      buttonStart: 1,
                    });
                  }}
                >
                  1
                </button>
                ...
              </>
            ) : (
              []
            )}
            {buttons}
            {pageData?.page === pageData?.totalPages ||
            pageData?.buttonEnd === pageData?.totalPages ? (
              []
            ) : pageData.totalPages > pageData.buttonsCount ? (
              <>
                ...
                <button
                  onClick={() => {
                    setPageData({
                      ...pageData,
                      page: pageData.totalPages,
                      buttonEnd: pageData.totalPages,
                      buttonStart:
                        pageData.totalPages > pageData.buttonsCount
                          ? pageData.totalPages - (pageData.buttonsCount - 1)
                          : 1,
                    });
                  }}
                >
                  {pageData.totalPages}
                </button>
              </>
            ) : (
              []
            )}
            <button onClick={NextPage}>Next</button>
          </p>
        </>
      ) : (
        []
      )}
    </div>
  );
};

export default Pagination;
